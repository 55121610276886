import React from 'react';
import { List, Datagrid, TextField, EmailField } from 'react-admin';

const SourceList = props => (
    <List {...props}
    	perPage={100} 
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

export default SourceList;
