import React, { Component, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import Users from './Users';
import Offers from './Offers';
import Brands from './Brands';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

class Dashboard extends Component {

    constructor() {
        super();
        this.state = { 
            users: [], 
            offers: [],
            brands: [],
            userCount: 0,
            offerCount: 0,
            brandCount: 0
        };
    }

    async getUsers() {

        let response = await axios.get(`${config.API_URL}/users`, {
            params: {
                orderby: 'id',
                direction: 'DESC',
                limit: 10,
                admin: true
            },
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })

        let indexPosition = response.headers['content-range'].lastIndexOf('/')
        let userCount = response.headers['content-range'].substring(indexPosition + 1);

        console.log('users:', response.data)

        this.setState({ 
            users: response.data.data,
            userCount
        });
    }

    async getOffers() {

        let response = await axios.get(`${config.API_URL}/offers`, {
            params: {
                orderby: 'id',
                direction: 'DESC',
                limit: 5,
                status_id: 1,
                admin: true
            }
        })

        let indexPosition = response.headers['content-range'].lastIndexOf('/')
        let offerCount = response.headers['content-range'].substring(indexPosition + 1);

        this.setState({ 
            offers: response.data.data,
            offerCount
        });
    }

    async getBrands() {

        let response = await axios.get(`${config.API_URL}/brands`, {
            params: {
                orderby: 'id',
                direction: 'DESC',
                limit: 10,
                status_id: 1,
                admin: true,
                country_id: false
            }
        })

        let indexPosition = response.headers['content-range'].lastIndexOf('/')
        let brandCount = response.headers['content-range'].substring(indexPosition + 1);


        console.log('Michael: ', response.data.data)

        this.setState({ 
            brands: response.data.data,
            brandCount
        });
    }

    async componentDidMount() {
        await this.getUsers();
        await this.getOffers();
        await this.getBrands();

    }

    render() {
        return(
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <Users users={this.state.users} userCount={this.state.userCount} />
                    </div>
                </div>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <Brands brands={this.state.brands} brandCount={this.state.brandCount} />
                    </div>
                </div>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <Offers offers={this.state.offers} offerCount={this.state.offerCount} />
                    </div>
                </div>
            </div>
        );
    }
}


export default Dashboard;
