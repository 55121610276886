import React, {Component, useEffect, useState} from 'react';
import { Field } from 'react-final-form';
import AutoSuggest from '../components/AutoSuggest'
import Select from 'react-select';
import { List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, TextInput, EditButton, Edit, SimpleForm, LongTextInput, BooleanInput, DisabledInput, Create, ImageField, ImageInput, DateInput, TabbedForm, FormTab, ArrayField, UrlField, ReferenceManyField, SingleFieldList, ChipField, SelectArrayInput, ReferenceArrayInput, AutocompleteArrayInput, fetchUtils, CREATE,
    REDUX_FORM_NAME } from 'react-admin';
import config from '../config';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const API_URL = config.API_URL;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 20
  }),
  control: (provided, state) => ({
    ...provided,
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTopWidth: 0,
    borderRadius: 0,
    borderTopColor: 'transparent',
    padding: '10px 0',
    backgroundColor: '#f5f5f5',
    marginBottom: '20px'
  }),
  container: (provided) => ({
    ...provided,
    zIndex: 2,
    marginBottom: '20px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  }
}



const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
    <Select
      {...input}
			value={input.value && typeof input.value.map==='function' && input.value.map( b => ({
					label: b.name || b.label,
					value: b.id || b.value
				}))}
			{...custom}
    />
);


const BrandSelect = (props) => {
	const [query, setQuery] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	useEffect(()=>{
		console.log('query:', query);
		requestResults(query);
	}, [query]);

	const { classes, theme } = props;

	const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
    }),
  };


	function requestResults(queryText) {
		const { fetchJson } = fetchUtils;
		if(!queryText){
			setSuggestions([]);
			return;
		}

		let query = {
	      admin: true,
        country_id: false,
	      limit: 'disabled',
	      // page: page - 1,
	      // limit: 'perPage',
	      // orderby: field,
	      // direction: order,
	      // search: params.filter.q
	  };

	// http://localhost:3000/api/v1/brands?name=wal

		const url = `${API_URL}/brands?search=${queryText}&admin=true&country_id=false&limit=disabled`;
		const options = { method: 'GET' };

		if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

		const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    fetchJson(url, { cors: true, ...options} )
        .then(handleResponse)
        .catch( err => console.log('Failed request:', err));

	}

	function handleResponse(response) {
		console.log('Response:', response);
		if(response.json && response.json.data) {
			setSuggestions(response.json.data)
		}
	}

    return (
    	<span>
  			<Field 
  				name="brands"
  				component={renderTextField}
  		    classes={classes}
          styles={customStyles}
          textFieldProps={{
            label: props.label,
            InputLabelProps: {
              shrink: true,
            },
          }}
          options={suggestions.map( s => ({
  						label: s.name, 
  						value: s.id
  					}))}
          inputValue={query}
          onInputChange={setQuery}
          placeholder="Select Brands"
          isMulti
  				label="Brands"
  			/>
    </span>
    )
};


export default BrandSelect;