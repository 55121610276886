import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { fetchUtils } from 'react-admin';
import config from '../config';
import {Redirect} from 'react-router'
import DeleteIcon from '@material-ui/icons/Delete';


const makeRequest = ({ url, method, responseHandler, body }) => {
	const { fetchJson } = fetchUtils;
	url = `${config.API_URL}${url}`;
	const options = { method: method || 'GET' };

	if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }

	const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);

  if(body){
  	options.body = JSON.stringify(body);
  }

  fetchJson(url, { cors: true, ...options} )
      .then(responseHandler)
      .catch( err => console.log('Failed request:', err));
}

const BrandImageDelete = ({ record }) => {
	const [isComplete, setComplete] = useState(false);
	const deleteImage = () => {
		console.log('delete', record);
		makeRequest({
			url: `/brands/image/${record.id}`,
			method: 'DELETE',
			responseHandler: response => {
				console.log('[handleDeleteSourceResponse]', response);
				setComplete(true);
			},
		})
	}

	if(isComplete){
		return <Redirect to="/brands" />
	}

  if(record.image_slug)
      return <Button 
    						variant="contained"
        				color="secondary"
        				startIcon={<DeleteIcon />}
    						onClick={deleteImage}>
    						DELETE IMAGE
    				</Button>;
  else
      return <div></div>;
};

 export default BrandImageDelete;