import OfferList from './OfferList';
import OfferCreate from './OfferCreate';
import OfferEdit from './OfferEdit';


export default {
    list: 	OfferList,
    create: OfferCreate,
    edit: 	OfferEdit,
};
