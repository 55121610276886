import React from 'react';
import { 
	Create, 
	SimpleForm, 
	TextInput 
} from 'react-admin';

const BrandCreate = props => {
	return(
		 <Create undoable={false} {...props}>
        <SimpleForm redirect="edit">
            <TextInput source="name" label="Brand Title" fullWidth />
        </SimpleForm>
    </Create>
	);
};

export default BrandCreate;
