import React, {Component} from 'react';
import { Field, Form } from 'react-final-form';
import { Datagrid, Filter, ReferenceInput, SelectInput, TextInput, EditButton, Edit, SimpleForm, LongTextInput, BooleanInput, DisabledInput, Create, ImageField, ImageInput, DateInput, TabbedForm, FormTab, ArrayField, UrlField, ReferenceManyField, SingleFieldList, ChipField, SelectArrayInput, ReferenceArrayInput, AutocompleteArrayInput, fetchUtils, CREATE,
    REDUX_FORM_NAME } from 'react-admin';
// import { change } from 'redux-form';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import classNames from 'clsx';
//import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

// import { withRouter } from 'react-router-dom'

import config from '../config';
const API_URL = config.API_URL;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const AddedSource = ({id, source_id, brand_id, source_brand_id, allSources, onDeleteRequest}) => {
	const source = allSources.find(s=>s.id === source_id);
	if(!source) return <div>...</div>

	return (

		<ListItem
      // key={value}
      role={undefined}
      dense
      button
      // onClick={this.handleToggle(value)}
      // className={classes.listItem}
    >
      <ListItemText primary={ `${source.name} - ${source_brand_id}` } />
      <ListItemSecondaryAction>
        <IconButton aria-label="Delete" onClick={onDeleteRequest(id)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
	)
}

class BrandSourceSelect extends Component{

	state = {
		selected_source_id: '',
		source_brand_id: '',
		newlyAddedSources: [],
		deletedSourceIds: [],
		sources: []
	}

	componentDidMount(){
		this.requestSources();
	}

	requestSources = () => {
		this.makeRequest({
			url: '/sources',
			method: 'GET',
			responseHandler: this.handleSourcesResponse
		})
	}


	makeRequest = ({ url, method, responseHandler, body }) => {
		const { fetchJson } = fetchUtils;
		url = `${API_URL}${url}`;
		const options = { method: method || 'GET' };

		if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

		const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);

    if(body){
    	options.body = JSON.stringify(body);
    }

    fetchJson(url, { cors: true, ...options} )
        .then(responseHandler)
        .catch( err => console.log('Failed request:', err));
	}



	handleSourcesResponse = response => {
		console.log('handleSourcesResponse Response:', response);
		if(response.json && response.json.data) {
			this.setState({ sources: response.json.data })
		}
	}

	addSource = e => {
		//console.log('[addSource]:', values)
		e.preventDefault();
		e.stopPropagation();

		const { selected_source_id, source_brand_id } = this.state;
		const { match: {params}} = this.props;


		//return; ////////////////////////////////////////////
		console.log('[addSource]', selected_source_id, source_brand_id);

		if(!selected_source_id || !source_brand_id){
			return;
		}

		const brandsData = this.props.admin.resources.brands.data;
		// const brandsDataKeys = Object.keys(brandsData);

		this.makeRequest({
			url: '/sources/brands',
			method: 'POST',
			responseHandler: this.handleAddSourceResponse,
			body: {
				// brand_id: this.props.form['record-form'].id,
				brand_id: brandsData[params.id].id,
				source_id: selected_source_id,
				source_brand_id: this.state.source_brand_id
			}
		})
	}
	handleAddSourceResponse = response => {
		console.log('handleAddSourceResponse:', response);

		// const nextSources = [
		// 	...this.props.form['record-form'].values.sources,
		// 	response.json.data
		// ];

		// this.props.change(REDUX_FORM_NAME, 'sources', nextSources);

		this.setState(s =>({
					selected_source_id: null,
					source_brand_id: '',
					newlyAddedSources: [...s.newlyAddedSources, response.json.data]
				}))

	}


	update = e => this.setState({ [e.target.name]: e.target.value});

	onDeleteRequest = id => e => {
		e.preventDefault();
		e.stopPropagation();

		console.log('DELETE', id);

		this.makeRequest({
			url: `/sources/${id}`,
			method: 'DELETE',
			responseHandler: response => {
				console.log('[handleDeleteSourceResponse]', response);

				this.setState(s=>({deletedSourceIds: [...s.deletedSourceIds, id]}))

				// const nextSources = this.props.form['record-form'].values.sources.filter(
				// 	source => source.id !== id
				// )

				// this.props.change(REDUX_FORM_NAME, 'BrandSourceSelect', nextSources);

			},
		})
	}




	render(){
		const { sources, newlyAddedSources, deletedSourceIds, selected_source_id, source_brand_id } = this.state;
		const { admin, match } = this.props;

		const brandsData = this.props.admin.resources.brands.data;
		// const brandsDataKeys = Object.keys(brandsData);

		let addedSources = brandsData[match.params.id].sources || [];
		// if(form && form['record-form'] && form['record-form'].values && form['record-form'].values.sources){
		// 	addedSources = form['record-form'].values.sources
		// }
		const allSources = []

		return (
			<div>

						<Typography variant="title" style={{padding:'16px 0 0 0'}}>Added sources</Typography>
						<Paper style={{marginTop:16}}>
							<List>
							{ [...addedSources, ...newlyAddedSources].filter(s=>!deletedSourceIds.includes(s.id)).map( addedSource =>(
								<AddedSource key={addedSources.id} onDeleteRequest={this.onDeleteRequest} {...addedSource} allSources={[...sources, ...newlyAddedSources]} />
							))}
			        </List>
						</Paper>

		           {/*<ReferenceInput source="source_id" reference="sources">
		                <SelectInput optionText="name" />
		            </ReferenceInput>*/}

		        <Typography variant="title" style={{padding:'16px 0 0 0', display: 'block'}}>Add Source</Typography>
		        <div style={{display: 'flex', alignItems: 'baseline'}}>

		        <FormControl
		        style={{marginRight:24, marginTop: 0}}
		        >
		          {/*<InputLabel htmlFor="selected_source_id">Source</InputLabel>*/}
			        <Select
			            value={selected_source_id}
			            onChange={this.update}
			            name="selected_source_id"
			          >
			          <MenuItem value=""> <em>Select Source</em> </MenuItem>
			          {sources.map(source => (
			          	<MenuItem key={source.id} value={source.id}>{source.name}</MenuItem>
								))}
			        </Select>


		        </FormControl>

						{/*<select value={selected_source_id} onChange={this.update} name="selected_source_id">
							<option></option>
							{sources.map(source => (
								<option key={source.id} value={source.id}>{source.name}</option>
							))}
						</select>*/}

						<TextField
		          id="source_brand_id"
		          name="source_brand_id"
		          label="Source Brand ID"
		          // className={classes.textField}
		          value={source_brand_id}
		          onChange={this.update}
		          margin="normal"
		          style={{marginRight:24}}
		        />

						<Button variant="contained" onClick={this.addSource}
						disabled={!selected_source_id || !source_brand_id}
						>
			        Add
			      </Button>
			     </div>

			</div>
		)
	}
}


const mapStateToProps = state => ({
	// form: state.form
	admin: state.admin
});

const mapDispatchToProps = {
    // change
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BrandSourceSelect));
