// in src/Menu.js
import React, { createElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, DashboardMenuItem } from 'react-admin';
import { withRouter } from 'react-router-dom';

import LabelIcon from '@material-ui/icons/Label';

import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import PermMediaOutlinedIcon from '@material-ui/icons/PermMediaOutlined';
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined';
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import CloudCircleOutlinedIcon from '@material-ui/icons/CloudCircleOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import Battery30Icon from '@material-ui/icons/Battery30';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';

import AddIcon from '@material-ui/icons/Add';

import SubMenu from './SubMenu';


const Menu = ({ onMenuClick, dense, logout }) => {

    const [state, setState] = useState({
        menuMisc: false,
        menuOffers: false,
        menuBrands: false,
        menuUsers: false
    });

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    const handleToggle = menu => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            <SubMenu
                icon={<LocalOfferOutlinedIcon />}
                handleToggle={() => handleToggle('menuOffers')}
                isOpen={state.menuOffers}
                sidebarIsOpen={open}
                name="Offers"
                dense={dense}
            >
                <MenuItemLink
                    leftIcon={<LocalOfferOutlinedIcon />}
                    to={`/offers`}
                    primaryText={'All Offers'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<Battery30Icon />}
                    to={`/offers/pending`}
                    primaryText={'Pending US Offers'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<BatteryFullIcon />}
                    to={`/offers?filter={"source_id":"23", "status_id": "1"}&order=DESC&page=1&perPage=100&sort=id&country_id=2&status_id=1`}
                    primaryText={'Published US Offers'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />  
                <MenuItemLink
                    leftIcon={<PersonIcon />}
                    to={`/offers?filter={"user_id":"2145"}&order=DESC&page=1&perPage=25&sort=id`}
                    primaryText={"Rose's Offers"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<PersonIcon />}
                    to={`/offers?filter={"country_id":"2","offer_type_id":"3","status_id":"1", "where_null": "description_1"}&order=DESC&page=1&perPage=100&sort=id`}
                    primaryText={"VTF Offers ToDo"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<PeopleIcon />}
                    to={`/offers?filter={"country_id":"2","offer_type_id":"3","status_id":"1", "where_not_null": "description_1"}&order=DESC&page=1&perPage=100&sort=id`}
                    primaryText={"VTF Offers Complete"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<LibraryBooksIcon />}
                    to={`/offers?filter={"country_id":"2","offer_type_id":"3","status_id":"1"}&order=DESC&page=1&perPage=25&sort=id`}
                    primaryText={'US Flyers'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />               
                <MenuItemLink
                    leftIcon={<CollectionsBookmarkIcon />}
                    to={`/offers?filter={"user_id":"2496"}&order=DESC&page=1&perPage=25&sort=id`}
                    primaryText={"US Flyers +"}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />       
                <MenuItemLink
                    leftIcon={<AddCircleOutlineOutlinedIcon />}
                    to={`/offers/create`}
                    primaryText={'Add New Offer'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                icon={<LocalMallOutlinedIcon />}
                handleToggle={() => handleToggle('menuBrands')}
                isOpen={state.menuBrands}
                sidebarIsOpen={open}
                name="Brands"
                dense={dense}
            >
                <MenuItemLink
                    leftIcon={<LocalMallOutlinedIcon />}
                    to={`/brands`}
                    primaryText={'All Brands'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<AddCircleOutlineOutlinedIcon />}
                    to={`/brands/create`}
                    primaryText={'Add New Brand'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                icon={<SentimentVerySatisfiedIcon />}
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                sidebarIsOpen={open}
                name="Users"
                dense={dense}
            >
                <MenuItemLink
                    leftIcon={<SentimentVerySatisfiedIcon />}
                    to={`/users`}
                    primaryText={'All'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<SentimentVerySatisfiedIcon />}
                    to={`/users?filter={"app_id":"2"}`}
                    primaryText={'Flyerify'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<SentimentVerySatisfiedIcon />}
                    to={`/users?filter={"app_id":"1"}`}
                    primaryText={'Savespree'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<SentimentVerySatisfiedIcon />}
                    to={`/users?filter={"app_id":"3"}`}
                    primaryText={'SmartCanucks'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
             <SubMenu
                icon={<PermMediaOutlinedIcon />}
                handleToggle={() => handleToggle('menuMisc')}
                isOpen={state.menuMisc}
                sidebarIsOpen={open}
                name="Misc"
                dense={dense}
            >
                <MenuItemLink
                    leftIcon={<GroupWorkOutlinedIcon />}
                    to={`/offerTypes`}
                    primaryText={'Offer Types'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<SwapHorizontalCircleOutlinedIcon />}
                    to={`/offerActions`}
                    primaryText={'Offer Actions'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<CheckCircleOutlinedIcon />}
                    to={`/statuses`}
                    primaryText={'Statuses'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<CloudCircleOutlinedIcon />}
                    to={`/sources`}
                    primaryText={'Sources'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<CloudCircleOutlinedIcon />}
                    to={`/countries`}
                    primaryText={'Countries'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    leftIcon={<CloudCircleOutlinedIcon />}
                    to={`/apps`}
                    primaryText={'Apps'}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            {isXSmall && logout}
        </div>
    );
};

export default withRouter(Menu);