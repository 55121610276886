import React from 'react';
import { List, Datagrid, TextField, EmailField } from 'react-admin';

const OfferTypeList = props => (
    <List 
    	{...props}
    	title="Offer Types"
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

export default OfferTypeList;
