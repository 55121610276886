import React from 'react';
import { List, Datagrid, TextField, TextInput, EmailField, Filter } from 'react-admin';

const BrandFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

const BrandList = props => (
    <List 
    	{...props} 
    	perPage={25} 
    	sort={{ field: 'id', order: 'DESC' }}
    	filters={<BrandFilter />}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

export default BrandList;
