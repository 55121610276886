import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA1_CgEQDpXunm3HHn-WsZWu77XdfMI8TI",
    authDomain: "the-shopping-api.firebaseapp.com",
    databaseURL: "https://the-shopping-api.firebaseio.com",
    projectId: "the-shopping-api",
    storageBucket: "the-shopping-api.appspot.com",
    messagingSenderId: "8599652166",
    appId: "1:8599652166:web:78aaede085c85b136d8747"
};
firebase.initializeApp(firebaseConfig);


export default {
	// API_URL: 'http://localhost:3000/api/v1',
	API_URL: 'https://theshoppingapi.com/api/v1',
	db: firebase.database(),
	storage: firebase.storage(),
};
