import React from 'react';
import { List, Datagrid, TextField, EmailField } from 'react-admin';

const CountryList = props => (
    <List 
    	{...props}
    	title="Countries"
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
        </Datagrid>
    </List>
);

export default CountryList;
