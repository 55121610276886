import React, {useEffect, useState, useReducer, useRef} from 'react';
import axios from 'axios'
import config from '../config';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import randomCat from 'random-cat';


const db = config.db;
const photoUrl = `https://loremflickr.com/1000/600/kittens?${Math.random()}`

var catUrl2 = randomCat.get({
		  width: 1000,
		  height: 600
		}) + '?' + Math.random();

// var starCountRef = db.ref('mail-scraper-in-progress');
// starCountRef.on('value', function(snapshot) {
// 	console.log('mail-scraper-in-progress Changed!', snapshot.val())
//   // updateStarCount(postElement, snapshot.val());
// });



const API_URL = config.API_URL;
const url = `${API_URL}/offers`;
function getHeaders(){
	const token = localStorage.getItem('token');
	const headers = {
	  "Accept": 'application/json',
	  Authorization: `Bearer ${token}`
	};
	return headers;
}


async function getOffers() {


	const params = { 
	orderby: 'id',
    direction: 'desc',
    status_id: 4,
    limit: 50,
    page: 0,
    includes: 'brands',
    source_id: 23,
    country_id: 2
  }

  const offers = await axios.get(url, { headers: getHeaders(), params});

  return offers.data.data;
}

async function rejectOffer(offerId) {
  const result = await axios.post(`${url}/${offerId}/reject`, {}, { headers: getHeaders() });

  console.log('The rejection result: ', result);

  return true;
}

const getHexId = (offer) => {

/* global BigInt */

	console.log('Pre hex: ', offer.source_offer_id.toString(16)) 
	let hexed = BigInt(offer.source_offer_id).toString(16)
	console.log('Hexed: ', hexed)

	return hexed
	// return offer.source_offer_id.toString(16)
}


async function publishDeal(offer) {
	console.log('OFFER PUBLISH', offer);
	// offer.selectedImages = array urls of selected images

	let pictures = [];
	let bodyUrl;
	offer.selectedImages.forEach(img => {
		const [imgUrl, offerUrl] = img.src.split('>');
		pictures.push(imgUrl);
		if(!bodyUrl){ bodyUrl = offerUrl; }
	})

	let body = {
		name: offer.name,
		start_date: offer.start_date || moment().subtract(1, 'days').format(),
		publish_date: offer.publish_date || moment().subtract(1, 'days').format(),
		status_id: 1,
		offer_type_id: offer.offer_type_id,
		pictures,
		instructions: '',
		code: offer.code,
		description: offer.description,
		savings: offer.savings,
		checkUrlRedirect: true,
		end_date: offer.end_date,
		offer_action_id: offer.offer_action_id
	}

	if(bodyUrl && typeof bodyUrl==='string' && bodyUrl.includes("http")){
		body.url = bodyUrl
	}




	try {
		const updatedOffer = await axios.put(`${url}/${offer.id}`, body, { headers: getHeaders() });
		return updatedOffer;
	} catch (err){
		console.log('saving offer failed', err);
		console.dir( err);
		return err;
	}

	//console.log('The Updated offeR: ', updatedOffer)




}


async function duplicateOffer(offer) {

	let body = { 
		name: offer.name,
		user_id: 1
  }

  const result = await axios.post(url, body, { headers: getHeaders() });

  let newOffer = result.data.data;

  body = {
  	instructions: offer.instructions,
  	start_date: offer.start_date || moment().subtract(1, 'days').format(),
		publish_date: offer.publish_date || moment().subtract(1, 'days').format()
  }

  if (offer.brands && offer.brands.length) {
    body.brands = [{
      brand_id: offer.brands[0].id
    }]
  }

  console.log('THe NEW OFFER TO SEND: ', body);

  const updatedOffer = await axios.put(`${url}/${newOffer.id}`, body, { headers: getHeaders() });

  return updatedOffer.data.data;
}


const Offer = ({offer, refreshOffers, onPublish}) => {
	const [snackOpen, setSnackOpen] = useState(false);
	const [savings, setSavings] = useState('');
	const [couponCode, setCouponCode] = useState('');
	const [description, setDescription] = useState('');
	const [imgs, setImgs] = useState([]);
	const [savingStatus, setSavingStatus] = useState('');
	const [endDate, setEndDate] = useState( moment().add(1, 'weeks').format() ); // string 2020-08-15
	const [activeEndDateButton, setActiveEndDateButton] = useState('');
	const [selectedImgs, setSelectedImgs] = useState([]);// ids of selected images


	const inputEl = useRef(null);
	//const [offerUrl, setOfferUrl] = useState('');
	const [offerEdits, setOfferEdits] = useReducer((state, action)=>{
		switch(action.type){
			case 'toggle_name_edit': return {...state, nameIsEditing: !state.nameIsEditing};
			case 'set_name': return {...state, name: action.name };
			default: return state;
		}
	}, {
		name: offer.name,
		nameIsEditing: false
	});
	useEffect(()=>{
		if(offerEdits.nameIsEditing){
			inputEl.current.focus();
		}
	},[offerEdits.nameIsEditing])


	useEffect(()=>{
		if(offer && offer.instructions){
			const imgSources = offer.instructions.split('|');
			setImgs(imgSources.map((src, id) => {
				// setOfferUrl
				// if(!offerUrl){
				// 	const [imageUrl, offerUrl] = src.split('>');
				// 	if(imageUrl) setOfferUrl(offerUrl);
				// }

				return { src, id }
			}));
		}
	},[offer]);

	const handleCloseSnack = (event, reason) => {
		//if(reason==='clickaway') return;
		setSnackOpen(false);
	}

	return (
		<div>



<div class="row" style={{ display: 'flex' }}>
	<div class="column" style={{ paddingRight: 30}}>
		<h3>{offer.brands && offer.brands.length ? offer.brands[0].name : null}</h3>	
	</div>

	<div class="column" style={{ paddingTop: 10}}>
		<Button variant="outlined"  color="#757ce8" onClick={async e=>{
			let url = 'https://mail.google.com/mail/u/0/#all/' + getHexId(offer)
			console.log('URL::: ', url)
			window.open(url, '_blank').focus();
		}}>VIEW EMAIL</Button>
	</div>
		</div>

			<h4>
			{
				offerEdits.nameIsEditing ?
				<span>
					<input 
						ref={inputEl}
						value={offerEdits.name}
						onChange={e=>setOfferEdits({type:'set_name', name:e.target.value})} 
						onBlur={e=>setOfferEdits({type:'toggle_name_edit'})}
						style={{
							padding: '2px 3px',
					    minWidth: '80%',
					    fontWeight: 'inherit',
					    fontSize: 'inherit',
					    marginLeft: 5,
						}}
					/>
				</span>
				:
				<span 
					onClick={e=>setOfferEdits({type:'toggle_name_edit'})}
					style={{
						border: '1px dashed gray',
						padding: '2px 3px',
					}}
					>
					{offerEdits.name}
				</span>
			}
			</h4>


			<p>
			<input placeholder="Promo Code" style={{
				padding:'3px 5px',
				fontFamily:'monospace',
				width: 200,
				margin: '0 10px 0 0'
			}}
				onChange={e=>setCouponCode(e.target.value)}
				value={couponCode}
			/>
			</p>
			<p>
				<textarea placeholder="Description" style={{
					padding:'3px 5px',
					fontFamily:'monospace',
					width: 862,
					height: 200,
					margin: '0 10px 0 0'
				}}
					onChange={e=>setDescription(e.target.value)}
					value={description} >
				</textarea>
			</p>
			<p>
				<textarea placeholder="Excerpt" style={{
					padding:'3px 5px',
					fontFamily:'monospace',
					width: 862,
					height: 30,
					margin: '0 10px 0 0'
				}}
					onChange={e=>setSavings(e.target.value)}
					value={savings}
				>
				</textarea>
			</p>



<div>Expires in: 
	<button onClick={e=>{
		setEndDate(  moment().add(1, 'days').format()  );
		setActiveEndDateButton('1d');
	}} disabled={activeEndDateButton==='1d'}>1 day</button>

	<button onClick={e=>{
		setEndDate(  moment().add(2, 'days').format()  );
		setActiveEndDateButton('2d');
	}} disabled={activeEndDateButton==='2d'}>2 days</button>

	<button onClick={e=>{
		setEndDate(  moment().add(3, 'days').format()  );
		setActiveEndDateButton('3d');
	}} disabled={activeEndDateButton==='3d'}>3 days</button>

	<button onClick={e=>{
		setEndDate(  moment().add(4, 'days').format()  );
		setActiveEndDateButton('4d');
	}} disabled={activeEndDateButton==='4d'}>4 days</button>

	<button onClick={e=>{
		setEndDate(  moment().add(5, 'days').format()  );
		setActiveEndDateButton('5d');
	}} disabled={activeEndDateButton==='5d'}>5 days</button>


	<button onClick={e=>{
		setEndDate(  moment().add(1, 'weeks').format()  );
		setActiveEndDateButton('1w');
	}} disabled={activeEndDateButton==='1w'}>1 week</button>
	<button onClick={e=>{
		setEndDate(  moment().endOf('month').format()  );
		setActiveEndDateButton('mEnd');
	}} disabled={activeEndDateButton==='mEnd'}>End of month</button>
	<button onClick={e=>{
		setEndDate(  moment().endOf('year').format()  );
		setActiveEndDateButton('yEnd');
	}} disabled={activeEndDateButton==='yEnd'}>End of year</button>
</div>

<br/>


			


			<Button variant="outlined"  color="#757ce8" onClick={e=>{
				console.log('Button1:', {...offer, 
						name: offerEdits.name,
						offer_type_id: 1,
						code: couponCode,
						description: description.replace(/\n/g, "<br />"),
						savings: savings,
						selectedImages: selectedImgs.map(id=>imgs.find(img=>img.id===id)),
						end_date: endDate,
						// url: offerUrl
					})
				if(typeof onPublish==='function'){
					onPublish({...offer, 
						name: offerEdits.name,
						offer_type_id: 1,
						code: couponCode,
						description: description.replace(/\n/g, "<br />"),
						savings: savings,
						selectedImages: selectedImgs.map(id=>imgs.find(img=>img.id===id)),
						end_date: endDate,
						// url: offerUrl
					});
				}
				// setSavingStatus('Saving Offer');
				// setSnackOpen(true);
				// await publishDeal({
				// 	...offer,
				// 	name: offerEdits.name,
				// 	code: couponCode,
				// 	selectedImages: selectedImgs.map(id=>imgs.find(img=>img.id===id)),
				// 	// url: offerUrl
				// });
				// setSavingStatus('Offer Saved');
				// setSnackOpen(true);
				// setTimeout(()=>{setSavingStatus('')},3000);
				// refreshOffers();
			}}
				disabled={!selectedImgs || !selectedImgs.length || savingStatus}
			>{savingStatus || "Publish as Deal"}</Button>

{/*			<button
				disabled={!selectedImgs || !selectedImgs.length || savingStatus}
			>{savingStatus || "Publish as Flyer"}</button>*/}

			<Button variant="outlined"  color="#757ce8" onClick={e=>{
				console.log('Button2:',{...offer, 
						name: offerEdits.name,
						offer_type_id: 3,
						offer_action_id: 4,
						code: couponCode,
						description: description,
						savings: savings,
						selectedImages: selectedImgs.map(id=>imgs.find(img=>img.id===id)),
						end_date: endDate,
						// url: offerUrl
					})
				if(typeof onPublish==='function'){
					onPublish({...offer, 
						name: offerEdits.name,
						offer_type_id: 3,
						offer_action_id: 4,
						code: couponCode,
						description: description,
						savings: savings,
						selectedImages: selectedImgs.map(id=>imgs.find(img=>img.id===id)),
						end_date: endDate,
						// url: offerUrl
					});
				}
				// setSavingStatus('Saving Offer');
				// setSnackOpen(true);
				// await publishDeal({
				// 	...offer,
				// 	name: offerEdits.name,
				// 	code: couponCode,
				// 	selectedImages: selectedImgs.map(id=>imgs.find(img=>img.id===id)),
				// 	// url: offerUrl
				// });
				// setSavingStatus('Offer Saved');
				// setSnackOpen(true);
				// setTimeout(()=>{setSavingStatus('')},3000);
				// refreshOffers();
			}}
				disabled={!selectedImgs || !selectedImgs.length || savingStatus}
			>{savingStatus || "Publish as Flyer"}</Button>



			<Button variant="outlined"  color="#757ce8" onClick={async e=>{
				await duplicateOffer({
					...offer,
					name: offerEdits.name
				});
				refreshOffers();
			}}>Duplicate</Button>

			<Button variant="outlined"  color="#757ce8" onClick={async e=>{
				await rejectOffer(offer.id);
				refreshOffers();
			}}>Reject</Button>


		


		
			<Images offer={offer}
				imgs={imgs}
			 	setImgs={setImgs}
			 	selectedImgs={selectedImgs}
			 	setSelectedImgs={setSelectedImgs}
			 />
			<hr/>


		</div>
	)
}

const Images = ({offer, imgs, setImgs, selectedImgs, setSelectedImgs}) => {

		const setImageSize =(id, width, height)=>{
			setImgs(imgs =>{
				//console.log('[setImageSize]',index, width, height);
				let nextImgs = [...imgs];
				const index = nextImgs.findIndex(i=>i.id===id)

				nextImgs[index] = {
					...nextImgs[index],
					width,
					height,
					area: width * height
				};
				nextImgs.sort((a, b)=> {
					let aArea = (a ? +a.area : 0);
					if(aArea!==aArea) aArea=0;
					let bArea = (b ? +b.area : 0);
					if(bArea!==bArea) bArea=0;
					return bArea - aArea;
				});

				return nextImgs
			});
    }

    if (!offer) {
      return <div>...</div>
    }

    if(!offer.instructions) {
      return <div>...</div>
    }

    // let imgs = []

    // imgs = offer.instructions.split('|');

    let sortedImgs = [];
    if(imgs){
    	let imgss=imgs.slice(0);
    	selectedImgs.forEach(id=>{
    		const index = imgss.findIndex(img=>id===img.id);
    		sortedImgs.push(imgss[index]);
    		imgss = [...imgss.slice(0,index), ...imgss.slice(index+1)];
    	});
    	sortedImgs = sortedImgs.concat(imgss);
    }

    return (
    	<div style={{
    		display: 'flex',
    		flexWrap: 'wrap'
    		// gridTemplateColumns: 'repeat(4, 1fr)',
    		// gridGap: 10,
    	}}>{
    	sortedImgs.map(img => {

    		const selectedIndex = selectedImgs.findIndex(id => id===img.id); //-1 if not selected
    		const isSelected = selectedIndex>-1;

    		return (
    	          <TheImage
    	            	key={img.id}
    	            	img={img}
    	            	offer={offer}
    	            	setImageSize={setImageSize}
    	            	isSelected={isSelected}
    	            	selectedIndex={selectedIndex}
    	            	toggleSelect={()=>{
    	            		if(!isSelected){ // select
    	            			setSelectedImgs([...selectedImgs, img.id])
    	            		} else { // deselect
    	            			setSelectedImgs(selectedImgs.filter(id=>id!==img.id));
    	            		}
    	            	}}
    	          />
    	        )})
    	}
    	</div>
    	);
    	
  }

const TheImage = ({img, index, offer, setImageSize, isSelected, toggleSelect, selectedIndex}) => {
	const [height, setHeight] = useState(null);
	const [width, setWidth] = useState(null);
	//const imageEl = useRef(null);
	useEffect(()=>{
		const im = new Image();
		im.onload = () => {
			if(im.naturalHeight) {
				console.log('naturalHeight', im.naturalHeight);
				setHeight(im.naturalHeight);
				setWidth(im.naturalWidth);
				setImageSize(img.id, im.naturalWidth, im.naturalHeight)
				//&& imageEl.current.naturalHeight<100
				//return null;
			} else {
				console.log('could not get height')
			}
		}
		im.src = img.src.split('>')[0];
		// if(imageEl.current && imageEl.current.onLoad){
		// 	console.log('Start listening load')
		// } else {
		// 	console.log(' COuld not Start listening load')
		// }
	},[]);

	if(
		typeof height==='number' && height < 250
		&& typeof width==='number' && width < 250
		) {
		return null;
	}
	
  return (
  	<div style={{
  		width: 260,
  		// height: 200,
  		padding: 10,
  		borderWidth: 2,
  		borderStyle: 'solid',
  		borderColor: isSelected? '#3ea3ea': '#dadada',
  		// #3ea3ea - blue for selected
  		margin: 5,
  		position: 'relative',
  		//display: 'inline-block'
  		cursor: 'pointer',
  	}}
  	onClick={toggleSelect}
  	> 
    <img
    	//ref={imageEl}
	    src={img.src.split('>')[0]} 
	    style={{
	    	width:'100%',
	    	maxWidth: '100%',
	    }}
	    
    id={`${offer.id}:${index}`} 
    	//onClick={this.changeStyle} style={
   	//  (this.state.selected[offer.id] && ( this.state.selected[offer.id] == index) ) ? imgStyle: notSelectedStyle
   //}
   />

	   {isSelected && <div style={{
	   	position:'absolute',
	   	bottom: 5,
	   	right: 5,
	   	color: 'white',
	    background: '#062450',
	    borderRadius: 50,
	    width: 30,
	    height: 30,
	    lineHeight: '30px',
	    textAlign: 'center',
	    opacity: 0.8,

	   }}>{selectedIndex+1}</div>}
   
   </div>
  )
}

const OfferPending = props => {
	const [offers, setOffers] = useState();
	const [offersRandomKey, setOffersRandomKey] = useState(1);
	const [snackMessages, setSnackMessages] = useState([]);

	const [mailScraperInProgress, setMailScraperInProgress] = useState(false);	

	useEffect(()=>{
		const ref = db.ref('mail-scraper-in-progress');
		ref.on('value', snapshot => {
			const val = snapshot.val();
			console.log('mail-scraper-in-progress Changed!', val);
		  // updateStarCount(postElement, snapshot.val());
		  setMailScraperInProgress(val);
		});
		return () => {
			console.log('stopping listening mail-scraper-in-progress')
			ref.off();
		}
	},[]);

	useEffect(()=>{
		const gettingOffers = async () => {
			const offers = await getOffers();
			console.log('OFFERS:', offers);
			setOffers(offers);
		}
		gettingOffers();

		//return ()=>{}
	},[offersRandomKey]);


	if(mailScraperInProgress) {

		var catUrl = randomCat.get({
		  width: 1000,
		  height: 600
		}) + '?' + Math.random();


		return (
			<div>
				<p>Pending offers are currently being updated. Page will be back within 5 minutes!</p>
				<img src={catUrl} />
			</div>
		)
	}



	const handleCloseSnack = (index) => {
		setSnackMessages(snackMessages.map((m,i) => {
			if(i!==index) return m;
			return {...m, message: ''};
		}))
	}

	
	const addSnackMessage = (message, variant) => setSnackMessages(s=> ([...s, {message, variant}]));

	const refreshOffers = () => setOffersRandomKey(Date.now());

	const handlePublish = async (offer) => {
		setOffers(offers.filter(o=>o.id!==offer.id));

		// setSavingStatus('Saving Offer');
		// setSnackOpen(true);
		addSnackMessage(`'Saving: ${offer.name}`, 'info');
		const resp = await publishDeal(offer);

		if(resp.isAxiosError) {
			addSnackMessage(`Error saving: ${offer.name}`, 'error');
		} else {
			addSnackMessage(`Saved: ${offer.name}`, 'success');
		}


		await new Promise(r=>setTimeout(r,2000))
		refreshOffers();
		// window.location.reload()

	}




	return (
	<div>
		<h1>Pending Offers</h1>

		{offers && !offers.length && (
			<div>
				<p>Pending offers are up to date 🙂</p>
				<img src={photoUrl}></img>
			</div>
		)}

		{offers && offers.length && offers.map(offer => 
			<Offer key={offer.id} offer={offer} 
				onPublish={handlePublish}
				refreshOffers={refreshOffers} 
				/>
		)}



		{snackMessages.map((snack,i) => {
			return (
				<Snackbar
		      anchorOrigin={{
		        vertical: 'bottom',
		        horizontal: 'right',
		      }}
		      //variant={snack.variant}
		      open={!!snack.message}
		      autoHideDuration={4000}
		      onClose={e=>handleCloseSnack(i)}
		    >

			    <SnackbarContent
			    	style={{
			    		backgroundColor: (()=>{
			    			if(snack.variant==='success') return green[600];
			    			if(snack.variant==='error') return '#d32f2f';
			    			return '#1976d2';
			    		})()
			    	}}
			      message={<span id={"message-"+i}>{snack.message}</span>}
			      aria-describedby={'message-'+i}
			      action={[
			        <IconButton
			          key="close"
			          aria-label="close"
			          color="inherit"
			          //className={classes.close}
			          onClick={e=>handleCloseSnack(i)}
			        >
			          <CloseIcon />
			        </IconButton>,
			      ]}

			    />


		    </Snackbar>
			)
		})}



	</div>
)};

export default OfferPending;
