import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import AppleIcon from '@material-ui/icons/Apple';

import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
});

const Users = ({ users, userCount }) => {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <CardIcon Icon={SentimentVerySatisfiedIcon} bgColor="#ff9800" />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {'Users'}
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {userCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
                <Divider />
                <List>
                  <ListItem
                      button
                      to={`/users`}
                      component={Link}
                      key={'title'}
                  >
                    <i><ListItemText
                      primary={'Latest Users'} /></i>
                  </ListItem>


                         {users
                        ? users.map(user => (
                             <ListItem
                                  button
                                  to={`/users/${user.id}`}
                                  component={Link}
                                  key={user.id}
                              >
                              <ListItemIcon>
                                <AppleIcon />
                              </ListItemIcon>
                                  <ListItemText
                                      primary={`${user.first_name} ${
                                          user.last_name
                                      }`}
                                  />
                              </ListItem>
                             ))
                        : null}
                        </List>
            </Card>
        </div>
    );
};

export default Users;
