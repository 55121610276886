import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CRUD_GET_MATCHING,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import { stringify } from 'query-string';
//import { API_URL, storage } from './config'

import config from './config';
const API_URL = config.API_URL;
const storage = config.storage;

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    if(!(file.rawFile instanceof File))
        return false;

    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const handleFileInputs = async params => {
    if(params.data && params.data.picture && params.data.picture) {
        params.data.picture = await convertFileToBase64(params.data.picture);
    }

    if(params.data && params.data.pictures && params.data.pictures.length) {
        params.data.pictures = await Promise.all(
            params.data.pictures.map(convertFileToBase64)
        );
    }
}

const handleFileUploads = async params => {

    // console.log('In handleFileUploads: ', params);


    if(params.data && params.data.picture) {
        // console.log('In if params.data.picture')
        params.data.picture = await uploadFile(params.data.picture);
    }

    if(params.data && params.data.pictures && params.data.pictures.length) {
        // console.log('In if params.data.pictures')
        params.data.pictures = await Promise.all(
            params.data.pictures.map(uploadFile)
        );
    }
}



const uploadFile = async file => {
    // console.log('In uploadFile: ', file);

    const filename = Date.now()+'_'+ Math.random();

    const ref = storage.ref().child('files/'+ filename  );

    try {
        await ref.put(file.rawFile);
    } catch (err){
        // console.log('ERROR uploading file', err);
    }

    const url = await ref.getDownloadURL();

    // console.log('Uploaded a blob or file!', url);

    return url;
    // }).catch(err => {
    //     console.log('ERROR uploading file', err);
    // });
}


/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = async (type, resource, params) => {
    // console.log('DATAA1', params);
    


    // console.log('Yaat: ', resource)

    if(resource === 'offers') {
        if(params?.data?.onlineimages) {
            let onlineimages = params.data.onlineimages;
            //let urls = onlineimages.match(/http.*\W/g);
            let lines = onlineimages.split(/[\n]+/g);
            let urls = [];
            let error = false;
            if(lines){
                lines.forEach(line => {
                    line = line.trim();
                    let url = line.match(/http.*\W/g);
                    if(url && url[0]) {
                        url = url[0];
                        // console.log('URL:', url.trim());
                        urls.push(url.trim());
                    } else {
                        // NOT URL!!
                        // console.log('NOT URL!!', line);
                        if(line) {
                            error = true;
                        }
                    }
                })
            }
            if(error){
                throw new Error(`Incorrect Image Format`);
            }
            // console.log("URLS:", urls);
            if(urls?.length) {
                urls = urls.map(m => m.trim());
                params.data.pictures = urls;
                delete params.data.onlineimages;
            } else {
                throw new Error(`Incorrect Image Format`);
            }
        }
        else {
            await handleFileUploads(params);
        }
    }
    else {
        await handleFileInputs(params);
    }


    


    // console.log('onlineimages: ', params, params?.data?.onlineimages)

    switch (type) {
    case GET_LIST: {
        // console.log('In GET_LIST QUERY', params)


        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        // console.log(params.filter);

        let query = {};


        let where_null
        if (params.filter.where_null)
            where_null = params.filter.where_null

        let where_not_null
        if (params.filter.where_not_null)
            where_not_null = params.filter.where_not_null

        if(params.filter.q && params.filter.q.length < 4) {
            query = {
                admin: true,
                country_id: false,
                page: page - 1,
                limit: perPage,
                orderby: field,
                direction: order,
                where_null: where_null,
                where_not_null: where_not_null
            };
        } else {
            query = {
                admin: true,
                country_id: false,
                page: page - 1,
                limit: perPage,
                orderby: field,
                direction: order,
                search: params.filter.q,
                where_null: where_null,
                where_not_null: where_not_null
            };
            
        }
            


            if(params.filter.user_id)
                query.user_id = params.filter.user_id;

            if(params.filter.source_id)
                query.source_id = params.filter.source_id;

            if(params.filter.country_id)
                query.country_id = params.filter.country_id;

            if(params.filter.offer_type_id)
                query.offer_type_id = params.filter.offer_type_id;

            if(params.filter.status_id)
                query.status_id = params.filter.status_id;

            if(params.filter.app_id)
                query.app_id = params.filter.app_id;

            if(resource === 'offers')
                query.includes = 'brands,sources';

            if(resource === 'brands')
                query.includes = 'sources';

        // if(resource.includes('brands')) {
        //     query = {
        //         admin: true,
        //         limit: 'disabled',
        //         // page: page - 1,
        //         // limit: 'perPage',
        //         orderby: field,
        //         direction: order,
        //         search: params.filter.q
        //     };
        // }
        // else {
        //     query = {
        //         admin: true,
        //         page: page - 1,
        //         limit: 5,
        //         orderby: field,
        //         direction: order,
        //         search: params.filter.q,
        //         includes: 'brands'
        //     };
        // }
        // console.log('query: ', resource);

        return { 
            url: `${API_URL}/${resource}?${stringify(query)}`, 
            options: {} 
        };
    };





    case GET_ONE:
        // console.log('In GET_ONE QUERY', params)

        let url = `${API_URL}/${resource}?country_id=false&id=${params.id}&admin=true`;

        if(resource === "offers")
            url += '&includes=brands,sources'

        if(resource === 'brands')
            url += '&includes=sources'

        return { 
            url,
            options: {}
        };
    case GET_MANY: {

        // console.log('In GET_MANY QUERY', params)
        const query = {
            admin: true,
            country_id: false,
            filter: JSON.stringify({ id: params.ids }),
        };

        // console.log('query: ', query);

        // console.log('query: ', params.ids[0])

        return { 
            url: `${API_URL}/${resource}?id=${params.ids[0]}`,
            options: {} 
        };

        // return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case GET_MANY_REFERENCE: {

        // console.log('In GET_MANY_REFERENCE QUERY');

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            [params.target]: params.id,
            includes: 'offers',
            country_id: false
            // admin: true,
            // sort: JSON.stringify([field, order]),
            // range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
            // filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
        };
        return { 
            url: `${API_URL}/${resource}?${stringify(query)}`,
            options: {}  
        };
    }
    case UPDATE:

        // console.log('IN UPDATEEE: ', params.data);

        if(resource.includes('offers')) {
            params.data.brands = params.data.brands ?  
                params.data.brands.map(brand => ({
                brand_id: brand.id || brand.value
            })) : [];
        }

        return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: { method: 'PUT', body: JSON.stringify(params.data) },
        };
    case CREATE:


        if(resource.includes('offers')) {
            params.data.brands = params.data.brands ?  
                params.data.brands.map(brand => ({
                brand_id: brand.id || brand.value
            })) : [];
        }

        return {
            url: `${API_URL}/${resource}`,
            options: { method: 'POST', body: JSON.stringify(params.data) },
        };
    case DELETE:
        return {
            url: `${API_URL}/${resource}/${params.id}`,
            options: { method: 'DELETE' },
        };
    default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { headers, json } = response;

    for(const [key, value] of headers.entries()) {
        // console.log(key, value);
    }
    // console.log(json, headers, headers.get('Content-Range'), headers.entries());
    switch (type) {
    case GET_LIST:

        // console.log('In GET_LIST, ', json)

        return {
            data: json.data.map(x => x),
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        };
    case GET_ONE:

        // console.log('In GET_ONE', json.data[0]);

           // const brandIds = json.data[0].brands.map( brand => brand.id );
        // console.log('brandIds:', brandIds)

        return {
            // data: {...json.data[0], brandIds },
            data: json.data[0],
            id: json.data[0].id
        };
    case GET_MANY:

        // console.log('In GET_MANY', json.data);
        return {
             data: json.data
        };
    case GET_MANY_REFERENCE:
        // console.log('In GET_MANY_REFERENCEEEE');



        return {
            data: json.data,
            total: json.data.length
        };

    case CREATE:
        // console.log('data: ', json.data);
        // console.log('id: ', json.data.id);
        return { data: { ...json.data, id: json.data.id } };
    case UPDATE:
        // console.log('data: ', json.data);
        // console.log('id: ', json.data.id);
        return { data: { ...json.data, id: json.data.id } };
    default:
        return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default async (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    const { url, options } = await convertDataProviderRequestToHTTP(type, resource, params);

    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);


    return fetchJson(url, { cors: true, ...options} )
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));

};