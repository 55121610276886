// in src/Layout.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from './themes';


const MyLayout = (props) => {
	const theme = useSelector(state =>
      state.theme === 'dark' ? darkTheme : lightTheme
  );

	return(
		<Layout 
			{...props} 
			menu={Menu}  
			appBar={AppBar}
			theme={theme} 
		/>
	);
}

export default MyLayout;