import React, { useMemo } from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AppleIcon from '@material-ui/icons/Apple';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import CardMedia from '@material-ui/core/CardMedia';


import CardIcon from './CardIcon';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    media: {
        height: '10em',
    }
});

const Offers = ({ offers, offerCount }) => {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <CardIcon Icon={LocalOfferOutlinedIcon} bgColor="#4cb050" />
            <Card className={classes.card}>

                <Typography className={classes.title} color="textSecondary">
                    {'Offers'}
                </Typography>
                <Typography
                    variant="h5"
                    component="h2"
                    className={classes.value}
                >
                    {offerCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Typography>
                <Divider />
                <List>
                  <ListItem
                      button
                      to={`/offers`}
                      component={Link}
                      key={'title'}
                  >
                    <i><ListItemText
                      primary={'Latest Offers'} /></i>
                  </ListItem>


                         {offers
                        ? offers.map(offer => (

                          <div>
                          { offer && offer.images && 
                            <CardMedia image={offer.images[0].url} className={classes.media}  />
                          }
                             <ListItem
                                  button
                                  to={`/offers/${offer.id}`}
                                  component={Link}
                                  key={offer.id}
                              >

                             
                                  <ListItemText
                                      primary={`${offer.name}`}
                                  />
                              </ListItem>
                              </div>
                             ))
                        : null}
                        </List>
            </Card>
        </div>
    );
};

export default Offers;
