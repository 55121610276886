import { AUTH_LOGIN, AUTH_CHECK, AUTH_ERROR, AUTH_LOGOUT, AUTH_GET_PERMISSIONS } from 'react-admin';

import config from './config';
const API_URL = config.API_URL;

const axios = require('axios');


export default async (type, params) => {

    // console.log('AUTH TYPE: ', type);

    if (type === AUTH_LOGIN) {

        try {
            const response = await axios.post(`${API_URL}/users/signin`, {
                email: params.username,
                password: params.password
            });

            localStorage.setItem('token', response.data.data.token);
            localStorage.setItem('username', response.data.data.email);


        }
        catch(e) {
            if (e.response.status === 401 || e.response.status === 403) {
                localStorage.removeItem('token');
                localStorage.removeItem('username');
                return Promise.reject();
            }

            if (e.response.status < 200 || e.response.status >= 300) {
                // console.log('ERRR: ', e.response.data.message)

                throw new Error(e.response.data.message);
            }
            return e.response.json();
        }
    }
    if (type === AUTH_LOGOUT) {

        // console.log('Logging out');

        try {
            
            // console.log('Logging out2');

            const token = 'Bearer ' + localStorage.getItem('token');
            // console.log('Logging out3', token);


            const response = await axios.post(`${API_URL}/users/signout`, '', {
                headers: { 
                    // "Accept": 'application/json',
                    "Authorization": token 
                }
            });

            // console.log('Logging out4');

            // const response = await axios.post({
            //     baseURL: `${API_URL}/users/signout`,
            //     headers: { 'Authorization': token }
            // });

            // console.log('LOCALLLLL: ', localStorage.token);

            localStorage.removeItem('token');
            localStorage.removeItem('username');

            // console.log('LOCALLLLL: ', localStorage.token);



            // options.headers.set('Authorization', `Bearer ${token}`);

            // localStorage.setItem('token', response.data.data.token);


                    // console.log('Logging out5');


        }
        catch(e) {
                    // console.log('Logging out6');


        }


        // localStorage.removeItem('token');
        // return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            return Promise.reject();
        }
        return Promise.resolve();

    }
     if (type === AUTH_CHECK) {

         const { resource } = params;

         // console.log('resource: ', resource)
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        return Promise.resolve();
    }

    return Promise.resolve();
}