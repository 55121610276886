import React from 'react';
import { List, Datagrid, TextField, TextInput, EmailField, ArrayField, SingleFieldList, ChipField, ReferenceField, Filter } from 'react-admin';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import FaceIcon from '@material-ui/icons/Face';


const OfferFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="User" source="user_id" defaultValue={1} />
    </Filter>
);

const OfferList = props => (
    <List 
        {...props} 
        perPage={25} 
        sort={{ field: 'id', order: 'DESC' }}
        filters={<OfferFilter />}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />

            <ArrayField source="brands" sortable={false}>
                <SingleFieldList linkType={false}>
                    <ChipField 
                    	size="medium" 
                    	variant="outlined" 
                    	source="name" 
                    	color="secondary"
                    />
                </SingleFieldList>
            </ArrayField>

            <ReferenceField source="user_id" reference="users" {...props}>
		        <TextField source="first_name" />
		    </ReferenceField>

        </Datagrid>
    </List>
);



export default OfferList;
