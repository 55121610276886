import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "react-responsive-modal";
import axios from "axios";
import config from "../config";
const API_URL = config.API_URL;

class OfferImageCropper extends PureComponent {
  state = {
    name: "",
    price: "",
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
    marks: [],
    showForm: false,
    crop: {
      unit: "px",
      width: 30
    }
  };
  componentDidMount() {
    this.setState({
      marks: [],
      showForm: false
    });
    const { offer_id, offer_page } = this.props;
    axios
      .get(`${API_URL}/items?offer_id=${offer_id}&offer_page=${offer_page}`)
      .then(res => {
        this.setState({
          marks: res.data.data
        });
      });
  }
  componentDidUpdate(prevProps) {
    const { offer_id, offer_page } = this.props;
    if (prevProps.offer_page !== offer_page) {
      this.setState({
        price: "",
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
        marks: [],
        showForm: false,
        crop: {
          unit: "px",
          width: 30
        }
      });
      axios
        .get(`${API_URL}/items?offer_id=${offer_id}&offer_page=${offer_page}`)
        .then(res => {
          this.setState({
            marks: res.data.data
          });
        });
    }
  }

  onImageLoaded = image => {
    const { height, width, naturalHeight, naturalWidth } = image;
    this.setState({
      height,
      width,
      naturalHeight,
      naturalWidth
    });
  };

  onCropComplete = crop => {
    const { height, width, naturalHeight, naturalWidth } = this.state;

    const x1 = parseInt((crop.x * naturalWidth) / width);
    const x2 = parseInt(x1 + (crop.width * naturalWidth) / width);
    const y1 = parseInt((crop.y * naturalHeight) / height);
    const y2 = parseInt(y1 + (crop.height * naturalHeight) / height);
    this.setState({
      x1,
      x2,
      y1,
      y2
    });
  };

  onCropChange = (crop, percentCrop) => {
    console.log(crop);
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = async () => {
    const { x1, x2, y1, y2, name, price, editId, width, height, naturalWidth, naturalHeight } = this.state;
    const { offer_id, offer_page } = this.props;
    const widthFactor = width / naturalWidth;
    const heightFactor = height / naturalHeight;
    if (name && price && !editId) {
      const token = "Bearer " + localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/items`,
        {
          x1,
          x2,
          y1,
          y2,
          name,
          price,
          offer_id,
          offer_page
        },
        {
          headers: {
            // "Accept": 'application/json',
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        const marks = [...this.state.marks];
        marks.push(response.data.data);
        this.setState({
          name: "",
          price: "",
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 0,
          showForm: false,
          marks,
          crop: {
            unit: "px",
            width: 30
          }
        });
      }
    } else if (name && price && editId) {
      const token = "Bearer " + localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/items/${editId}`,
        {
          x1: x1/widthFactor,
          x2: x2/widthFactor,
          y1: y1/widthFactor,
          y2: y2/widthFactor,
          name,
          price,
          offer_id,
          offer_page
        },
        {
          headers: {
            // "Accept": 'application/json',
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        const marks = [...this.state.marks];
        marks.push(response.data.data);
        this.setState({
          name: "",
          price: "",
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 0,
          showForm: false,
          editId: null,
          marks,
          crop: {
            unit: "px",
            width: 30
          }
        });
      }
    }
  };

  handleUndoEdit = () => {
    const { name, price, x1, x2, y1, y2, editId, width, height, naturalHeight, naturalWidth } = this.state;
    const marks = [...this.state.marks];
    const widthFactor = width / naturalWidth;
    const heightFactor = height / naturalHeight;
    marks.push({
      name,
      price,
      x1: x1/widthFactor,
      x2: x2/widthFactor,
      y1: y1/heightFactor,
      y2: y2/heightFactor,
      id: editId,
      marks
    });

    this.setState({
      name: "",
      price: "",
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
      showForm: false,
      editId: null,
      marks,
      crop: {
        unit: "px",
        width: 30
      }
    });
  };

  handleDelete = () => {
    const { editId } = this.state;
    if (editId) {
      const token = "Bearer " + localStorage.getItem("token");
      axios
        .delete(`${API_URL}/items/${editId}`, {
          headers: {
            Authorization: token
          }
        })
        .then(() => {
          this.setState({
            marks: this.state.marks.filter(mark => editId !== mark.id),
            name: "",
            price: "",
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 0,
            showForm: false,
            editId: null,
            crop: {
              unit: "px",
              width: 30
            }
          });
        });
    } else {
      this.setState({
        name: "",
        price: "",
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
        showForm: false,
        editId: null,
        crop: {
          unit: "px",
          width: 30
        }
      });
    }
  };

  renderContent = () => {
    const {
      crop,
      name,
      price,
      marks,
      naturalWidth,
      naturalHeight,
      width,
      height,
      showForm,
      editId
    } = this.state;
    const { image, className } = this.props;
    const widthFactor = width / naturalWidth;
    const heightFactor = height / naturalHeight;

    return (
      <>
        {image && (
          <div onClick={() => this.setState({ showForm: true })}>
            <ReactCrop
              src={image.replace("1000", "max")}
              crop={crop}
              onImageLoaded={this.onImageLoaded}
              onChange={this.onCropChange}
              onComplete={this.onCropComplete}
              className={className}
            />
          </div>
        )}
        {marks.map(mark => {
          const x1 = mark.x1 * widthFactor;
          const x2 = mark.x2 * widthFactor;
          const y1 = mark.y1 * heightFactor;
          const y2 = mark.y2 * heightFactor;

          return (
            <div
              onClick={() => {
                this.setState({
                  editId: mark.id,
                  marks: this.state.marks.filter(item => item.id !== mark.id),
                  name: mark.name,
                  price: mark.price,
                  showForm: true,
                  x1,
                  x2,
                  y1,
                  y2,
                  crop: {
                    x: parseInt(x1),
                    y: parseInt(y1),
                    width: parseInt(x2 - x1),
                    height: parseInt(y2 - y1)
                  }
                });
              }}
              style={{
                position: "absolute",
                width: parseInt(x2 - x1),
                height: parseInt(y2 - y1),
                left: parseInt(x1) + 15,
                top: parseInt(y1) + 15,
                border: "5px solid black",
                backgroundColor: "gray",
                opacity: 0.8,
                alignContent: "center"
              }}
            ></div>
          );
        })}
        {showForm && (
          <div
            style={{
              position: "absolute",
              left: (crop.x + crop.width) / 2.5,
              top: crop.y + crop.height + 50
            }}
          >
            <div>
              <input
                placeholder="Name"
                id="name"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <input
                placeholder="Price"
                id="price"
                name="price"
                value={price}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <button onClick={this.handleSubmit}>Submit</button>
            </div>
            <div>
              <button onClick={this.handleDelete}>Delete</button>
            </div>
            {editId && (
              <div>
                <button onClick={this.handleUndoEdit}>Undo Edit</button>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  render() {
    const { open, onClose, isFlyerPage } = this.props;

    return (
      <>
        {isFlyerPage ? (
          <div style={{ width: "100%", position: "relative" }}>
            {this.renderContent()}
          </div>
        ) : (
          <Modal open={open} onClose={onClose} center>
            {this.renderContent()}
          </Modal>
        )}
      </>
    );
  }
}

export default OfferImageCropper;
