import React from 'react';
import { List, Datagrid, TextField, EmailField, ReferenceField, Filter, TextInput } from 'react-admin';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="App" source="app_id" defaultValue={1} />
    </Filter>
);


const UserList = props => (
    <List 
    	{...props} 
    	perPage={25} 
    	sort={{ field: 'id', order: 'DESC' }}
        filters={<UserFilter />}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="email" />
            <ReferenceField source="app_id" reference="apps" {...props}>
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default UserList;
