import React from 'react';
import { List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, TextInput, EditButton, Edit, SimpleForm, BooleanInput, Create, ImageField, ImageInput, TabbedForm, FormTab } from 'react-admin';
import BrandSourceSelect from '../components/BrandSourceSelect'
import BrandImageDelete from '../components/BrandImageDelete';
import RichTextInput from 'ra-input-rich-text';


const BrandTitle = ({ record }) => {
     return <span>Brands - {record ? `${record.name}` : ''}</span>;
};

const BrandEdit = props => (
    <Edit title={<BrandTitle />} undoable={false} {...props}>
        <TabbedForm>
            <FormTab label="main">
                <TextInput disabled source="id" />
                <TextInput source="name" fullWidth />
                <TextInput source="slug" fullWidth/>
                <TextInput source="description" multiline fullWidth />
                <TextInput source="url" fullWidth />
                <BooleanInput source="accepts_generic_coupons" fullWidth />
                <ReferenceInput source="status_id" reference="statuses">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput source="country_id" reference="countries">
                    <SelectInput opftionText="name" />
                </ReferenceInput>
                <ImageField source="image" />

                <BrandImageDelete {...props}/>

                <ImageInput source="picture" label="file" accept="image/*" >
                    <ImageField source="image" title="title" />
                </ImageInput>
            </FormTab>

            <FormTab label="Sources">
                <BrandSourceSelect match={props.match} />
            </FormTab>

            <FormTab label="savespree">
                <TextInput source="savespree_slug" fullWidth/>
                <RichTextInput source="description_main_1" label="Savespree Main Description" multiline fullWidth />
                <RichTextInput source="description_secondary_1" label="Savespree Story Style Description" multiline fullWidth />
            </FormTab>

            <FormTab label="flyerify">
                <TextInput source="flyerify_slug" fullWidth/>
                <RichTextInput source="description_main_2" label="Flyerify Main Description" multiline fullWidth />
                <RichTextInput source="description_secondary_2" label="Flyerify Story Style Description" multiline fullWidth />
            </FormTab>            

        </TabbedForm>
    </Edit>
);

export default BrandEdit;