import React, { useState }  from 'react';
import { List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, TextInput, NumberInput, EditButton, Edit, SimpleForm, BooleanInput, Create, ImageField, ImageInput, DateInput, TabbedForm, FormTab, ArrayField, UrlField, ReferenceManyField, SingleFieldList, ChipField, SelectArrayInput, ReferenceArrayInput, AutocompleteArrayInput, Title, BooleanField, FunctionField, ReferenceField, useNotify, useRedirect } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import RichTextInput from 'ra-input-rich-text';

import BrandSelect from '../components/BrandSelect';
import OfferImageCropper from '../components/OfferImageCropper';

const regions = ['nl', 'pe', 'ns', 'nb', 'qc', 'on', 'mb', 'sk', 'ab', 'bc', 'yt', 'nt', 'nu',
   'al','ak','az','ar','ca','co','ct','de','dc','fl','ga',
   'hi','id','il','in','ia','ks','ky','la','me','md','ma',
   'mi','mn','ms','mo','mt','ne','nv','nh','nj','nm','ny','nc','nd',
   'oh','ok','or','pa','ri','sc','sd','tn','tx','ut',
   'vt','va','wa','wv','wi','wy'];

const regionsCA = ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"];
const regionsUS = ["AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"];

let firstImage = ""

const OfferTitle = ({ record }) => {
	return <span>Offers - {record ? `${record.name}` : ''}</span>;
};


const OfferSource = ({ record }) => {
};

const styles = {
    bigImage: {
        "max-height": '2000px', 
        "max-width": '1000px', 
        marginRight: '10px', 
        marginLeft: '10px', 
        marginBottom: '10px'
    },
    imageList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridGap: '10px', 
        width: '.8vw'
    },
    image: {
        "max-height": '160px', 
        "max-width": '130px', 
        marginRight: '10px', 
        marginLeft: '10px', 
        marginBottom: '10px'
    },
    flexList: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    }
};

const StyledImageField = withStyles(styles)(({ classes, ...props }) => {
    const [image, setImage] = useState();
    const [offer_page, setOfferPage] = useState();
    return <div className={classes.imageList}>
        {
            props.record.images && props.record.images.map((data, index)=>{
                return (<img className={classes.image} src={data.url} alt="flyer" onClick={()=>{
                    setOfferPage(index + 1)  
                    setImage(data.url)
                     
                 }}/>)
            })
        }

        {offer_page && <OfferImageCropper 
            open={!!image} 
            image={image} 
            offer_page={offer_page}
            onClose={() => setImage('')} 
            offer_id={props.record.id}
        />}
    </div>
});

const BigImageField = withStyles(styles)(({ classes, ...props }) => {
    const [bigImage, setBigImage] = useState();

    let url = ""
    if(props.record.images && props.record.images && props.record.images && props.record.images.length)
        url = props?.record?.images[0]?.url

    return (
        <>{url &&
            <img className={classes.bigImage} src={url} alt="flyer" />
        }
        </>
    )


    
});





const OfferEdit = withStyles(styles)(({ classes, ...props }) => {
    const notify = useNotify();
    // const redirect = useRedirect();
    const onSuccess = () => {
        console.log('OfferEdit SAVED on Success')
        notify('Offer updated successfully', 'info'); // default message is 'ra.notification.updated'
        //redirect('edit', props.basePath);
    }

    // const redirect = (basePath, id, data) => `/offers`;


    return (
     <Edit undoable={false} title={<OfferTitle />} {...props}>
        <TabbedForm 
            
            //onSuccess={onSuccess}
            >
            <FormTab label="main">

                <TextInput source="id" disabled />

                <TextInput variant="standard" source="name" fullWidth />

                <ReferenceInput source="offer_type_id" reference="offerTypes">
                    <SelectInput optionText="name" defaultValue={1} />
                </ReferenceInput>

                <ReferenceInput source="source_id" reference="sources">
                    <SelectInput optionText="name" defaultValue={1} />
                </ReferenceInput>

                <ReferenceInput source="user_id" reference="users">
                    <SelectInput optionText="first_name" defaultValue={1} disabled/>
                </ReferenceInput>

                <BrandSelect />
                
                <RichTextInput source="description" multiline fullWidth />
                <RichTextInput source="description_4" label="HotWeeklyAds Description" multiline fullWidth />
                <RichTextInput source="description_2" label="Flyerify Description" multiline fullWidth />


                <TextInput source="url" fullWidth />
                <TextInput source="code" />
                <BooleanInput source="feed" label="Show on Feed" fullWidth />
                <BooleanInput source="generic_coupon" label="Generic Coupon" fullWidth />
                <NumberInput 
                    source="reg_price"  
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                        }} 
                    label="Regular Price" 
                />
                <NumberInput 
                    source="sale_price"  
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                        }} 
                    label="Sale Price" 
                />

                <NumberInput 
                    source="discount_value"  
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                        }} 
                    label="Discount ($)" 
                />

                <NumberInput 
                    source="discount_percent"  
                    InputProps={{
                        endAdornment: (<InputAdornment position="start">%</InputAdornment>),
                        }} 
                    label="Discount (%)" 
                />

                <DateInput source="start_date" />
                <DateInput source="end_date" />

                <ReferenceInput source="status_id" reference="statuses">
                    <SelectInput optionText="name" />
                </ReferenceInput>


                <TextInput source="onlineimages" label="Image Urls" multiline fullWidth />



                <StyledImageField />




                <ImageInput source="pictures" label="file" accept="image/*" multiple>
                    <ImageField source="image" title="title" />
                </ImageInput>

            </FormTab>

            <FormTab label="advanced">
                <DateInput source="publish_date" />
                <TextInput source="redemption_limit" />
                <TextInput source="instructions" multiline fullWidth />
                <TextInput source="slug" fullWidth />

                <ReferenceInput source="offer_action_id" reference="offerActions">
                    <SelectInput optionText="name" />
                </ReferenceInput>

            </FormTab>

            <FormTab label="regions">
{/*                <BooleanInput source="region_nl" label="Newfoundland and Labrador" fullWidth />
                <BooleanInput source="region_pe" label="Prince Edward Island" fullWidth />
                <BooleanInput source="region_ns" label="Nova Scotia" fullWidth />
                <BooleanInput source="region_nb" label="New Brunswick" fullWidth />
                <BooleanInput source="region_qc" label="Quebec" fullWidth />
                <BooleanInput source="region_on" label="Ontario" fullWidth />
                <BooleanInput source="region_mb" label="Manitoba" fullWidth />
                <BooleanInput source="region_sk" label="Saskatchewan" fullWidth />
                <BooleanInput source="region_ab" label="Alberta" fullWidth />
                <BooleanInput source="region_bc" label="British Columbia" fullWidth />
                <BooleanInput source="region_yt" label="Yukon" fullWidth />
                <BooleanInput source="region_nt" label="Northwest Territories" fullWidth />
                <BooleanInput source="region_nu" label="Nunavut" fullWidth />
*/}


                <h2>US</h2>

                <div className={classes.flexList}>
                { regionsUS.map(region => {
                    return (
                        <BooleanInput source={`region_${region.toLowerCase()}`} label={region.toUpperCase()} fullWidth />
                    )
                })}
                </div>


                <h2>Canada</h2>
                <div className={classes.flexList}>
                { regionsCA.map(region => {
                    return (
                        <BooleanInput source={`region_${region.toLowerCase()}`} label={region.toUpperCase()} fullWidth />
                    )
                })}
                </div>




            </FormTab>


            <FormTab label="save">                
                  <RichTextInput source="description_1" label="List 10 deals from flyer" multiline fullWidth />
                                      <BigImageField />

            </FormTab>

        </TabbedForm>
    </Edit>
)});



export default OfferEdit;


