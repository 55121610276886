import React from 'react';
import { 
	Create, 
	SimpleForm, 
	TextInput,
	SelectInput,
} from 'react-admin';

import BrandSelect from '../components/BrandSelect';

const validateNotEmpty = (value) => {
	if (!value) {
        return 'The location type is required';
    }
};


const OfferCreate = props => {
	return(
		 <Create undoable={false} {...props}>
        <SimpleForm redirect="edit">
            <TextInput source="name" label="Offer Title" fullWidth validate={validateNotEmpty} />
            <BrandSelect />
            <SelectInput source="locationType" validate={validateNotEmpty} choices={[
						    { id: 'validAtAllLocations', name: 'All Regions' },
						    { id: 'validAtLocationsWithStore', name: 'Brand Relevant Regions' },
						    { id: 'none', name: 'None' },
						]} />
        </SimpleForm>
    </Create>
	);
};

export default OfferCreate;
