// in src/App.js
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import { Dashboard } from './dashboard';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import Layout from './layout/Layout';
import { Route } from 'react-router-dom'

import brands from './brands';
import offers from './offers';
import users from './users';
import offerTypes from './offerTypes';
import offerActions from './offerActions';
import statuses from './statuses';
import sources from './sources';
import countries from './countries';
import apps from './apps';
import OfferPending from './offers/OfferPending'

const theme = createMuiTheme({
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
  },
});

// const dataProvider = jsonServerProvider('http://jsonplaceholder.typicode.com');
const App = () => (
	<Admin 
		theme={theme}
		layout={Layout} 
		dataProvider={dataProvider}
		authProvider={authProvider}
		dashboard={Dashboard}
		customRoutes={[
			<Route 
				path="/offers/pending"
				component={OfferPending}
			/>
		]}
	>
		<Resource name="offers" {...offers} />
		<Resource name="brands" {...brands} />
		<Resource name="users" {...users} />
		<Resource name="offerTypes" {...offerTypes} />
		<Resource name="offerActions" {...offerActions} />
		<Resource name="statuses" {...statuses} />
		<Resource name="sources" {...sources} />
		<Resource name="countries" {...countries} />
		<Resource name="apps" {...apps} />
	</Admin>
);

export default App;