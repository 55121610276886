import React from 'react';
import { List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, TextInput, EditButton, Edit, SimpleForm, BooleanInput, Create, ImageField, ImageInput, TabbedForm, FormTab } from 'react-admin';

const UserTitle = ({ record }) => {
     return <span>Users - {record ? `${record.first_name} ${record.last_name}` : ''}</span>;
 };

const UserEdit = props => (
    <Edit title={<UserTitle />} {...props}>
        <TabbedForm>
            <FormTab label="main">
                <TextInput source="id" disabled/>
                <TextInput source="first_name" fullWidth />
                <TextInput source="last_name" fullWidth />
                <TextInput source="username" fullWidth />
                <TextInput source="email" fullWidth />
                <BooleanInput source="subscribed" label="Subscribed" fullWidth />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default UserEdit;